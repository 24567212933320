/* Custom CSS for LayersControl */
.leaflet-control-layers {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-family: 'Arial', sans-serif;
}

.leaflet-control-layers-toggle {
  background-image: url('https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/layers.png');
  width: 36px;
  height: 36px;
}

.leaflet-control-layers-expanded {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
}

.leaflet-control-layers-base label {
  font-weight: bold;
  color: #333;
  padding: 5px;
  display: block;
}

.leaflet-control-layers-base input {
  margin-right: 5px;
}

.leaflet-popup-content h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #333;
}

.leaflet-popup-content p {
  margin: 5px 0 0;
  padding: 0;
  color: #555;
}

/* Styles for the nested radio buttons */
.leaflet-control-layers-list input[type='radio'] {
  display: none; /* Hide the default radio buttons */
}

/* Custom toggle button styles */
.leaflet-control-layers-list label {
  display: inline-block;
  padding: 4px 8px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.6s ease;
  /* color: #555353; */

  font-weight: normal;
  margin: 2px;
}

/* Styles for the selected toggle button */
.leaflet-control-layers-list input[type='radio']:checked + label {
  background-color: #007bff;
  color: #fff;
}

/* Make the selected option visible */
.leaflet-control-layers-list input[type='radio']:checked ~ span {
  /* background-color: #007bff; */
  /* color: #fff; */
  color: #555353;
  font-weight: bold;
}

/* Optional: Styles for nested labels */
.leaflet-control-layers-list ul label {
  margin-left: 20px;
}

/* Make the selected option visible */
.leaflet-control-layers-list input[type='radio']:checked ~ ul {
  display: block;
}

.leaflet-control-layers-list ul {
  display: none;
  margin-left: 20px;
  padding-left: 10px;
  border-left: 2px solid #ccc;
}

.fullscreen-control {
  position: absolute;
  top: 10px;
  /* right: 10px; */
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 5px;
  cursor: pointer;
}
