.App {
  text-align: center;
}

h2 {
  padding-left: 1rem !important;
}

/* p {
  padding-left: 1rem !important;
} */

.color-nav {
  background-color: #688d93;
}
.navbar-light .navbar-nav .nav-link {
  /* color: #312b25; */
  color: rgb(205, 236, 241);
}

.nav-link.active {
  color: #fff !important;
}

.nav-link:hover {
  color: #fff !important;

  /* color: rgb(205, 236, 241) !important; */
}

a:link {
  color: #8b6361;
}

/* visited link */
a:visited {
  color: #8b6361;
}

/* mouse over link */
a:hover {
  color: gray;
}

/* selected link */
a:active {
  color: gray;
}
/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

.app-container {
  text-align: left;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Media query for larger screens */
@media (min-width: 1000px) {
  .app-container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}
